import { gsap } from "gsap";

// mobile nav functionality
const mobileNav = document.getElementById("mobile-nav-menu")
const navBtn = document.getElementById("mobile-nav-btn")
const navEx = document.getElementById("nav-menu-exit-btn")

if(navEx){
    navEx.addEventListener("click", function() {
        if(mobileNav.classList.contains("mobile-nav-active")) {
            mobileNav.classList.remove("mobile-nav-active")
            
        }   
    })
}
if(navBtn){
    navBtn.addEventListener("click", function() {
        if(!mobileNav.classList.contains("mobile-nav-active")) {
        mobileNav.classList.add("mobile-nav-active")
        
        }
    })
}

const btns = document.querySelectorAll(".btn")
if(btns.length){
    for (let i = 0; i < btns.length; i++) {
        const el = btns[i];
        if(el.classList.contains("border-blue")){
          if(location.pathname === "/about"){
            el.innerHTML = el.innerHTML + `<div id="btn-bar-${i}" class="transition-all absolute bg-blue" style="width: 100%; height:2px"></div>`
          }else{
            el.innerHTML = el.innerHTML + `<div id="btn-bar-${i}" class="transition-all absolute bg-blue" style="width: 0%; height:2px"></div>`
          }
          
        }else{
          if(el.classList.contains("btn-underline")){
            el.innerHTML = el.innerHTML + `<div id="btn-bar-${i}" class="transition-all absolute bg-white" style="width: 100%; height:2px"></div>`
          }else {
            el.innerHTML = el.innerHTML + `<div id="btn-bar-${i}" class="transition-all absolute bg-white" style="width: 0%; height:2px"></div>`
          }
          
        }
        
    }
    
    for (let i = 0; i < btns.length; i++) {
        const el = btns[i];
        const btnBar = document.getElementById(`btn-bar-${i}`)
        el.addEventListener("mouseover", () => {
            btnBar.style.width = "100%"
        })
        el.addEventListener("mouseout", () => {
            btnBar.style.width = "0%"
        })
    }
    
}

// Banner Swiper
const dots = document.querySelectorAll(".dot")

const aboutSwiper = new Swiper('.banner-swiper', {
    
    direction: 'horizontal',
    spaceBetween: 10,
    slidesPerView: 1,
    autoplay: {
        disableOnInteraction: true,
        delay: 5000
    }
    
});

aboutSwiper.on('slideChange', function (e) {
    
    if(dots){
        for (let i = 0; i < dots.length; i++) {
            const el = dots[i];
            let index = parseInt(getDotNumber(el.id))
            if(index === e.activeIndex){
                el.classList.add("bg-blue")
                el.classList.remove("border") 
            }else{
                el.classList.remove("bg-blue")
                el.classList.add("border")
            }
        }
    }

});

if(dots){
    for (let i = 0; i < dots.length; i++) {
        const el = dots[i];
        let index = parseInt(getDotNumber(el.id))
       
        el.addEventListener("click", () => {
            aboutSwiper.slideTo(index)
        })
    }
}

function getDotNumber(str) {
    return str.split('dot-')[1];
}

// insert arrows 
const links = document.querySelectorAll(".link-out")

if(links.length){
    for (let i = 0; i < links.length; i++) {
        const el = links[i];
        el.classList.add("flex", "gap-5")
        el.innerHTML = el.innerHTML + '<img src="/static/silverline-arrow-right.svg">'

        el.addEventListener("mouseover", () => {
            el.classList.remove("gap-5")
            el.classList.add("gap-10")
        })
        el.addEventListener("mouseout", () => {
            el.classList.remove("gap-10")
            el.classList.add("gap-5")
        })
    }
}

// simple animations
const banner = document.getElementById("banner")
const bannerContent = document.getElementById("banner-content")

if(banner && bannerContent){
    gsap.set(banner, {alpha: 0, y:50})
    gsap.set(bannerContent, {alpha: 0, y:50})

    const tl = gsap.timeline()

    tl
    .to(banner, {alpha: 1, y:0, ease: "expo"})
    .to(bannerContent, {alpha: 1, y:0, ease: "expo"})
}